
.entirediv {
  padding-top: 150px;
}

.entireheader {
  font-size: 100px;
  color: white;
}

.songdiv {
  border: 5px solid white;
  border-radius: 10px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  background: rgb(0,0,0,0.8);
  padding-right: 40px;
  padding-top: 20px;
}

@media (max-width: 475px) {
   .songdiv {
    width: 100%;
    border: none;
    border-radius: none;

  }
}

.wrapheader {
  width: 100%;
}

.songheader {
  color: white;
  font-size: 50px;
  right: 30;

}

@media (max-width: 475px) {
   .songheader {
    font-size: 40px;

  }
}


.listsongs {
  margin-right: auto;
  margin-left: auto;
}
