.App {
  text-align: center;
  margin: none;
  padding: none;


}

.fixed {
  position: fixed;
  width: 100%;
  z-index: 1000;
}



.App-header {
  margin-top: -14px;
  background: rgb(0,0,0);
  height: 80px;
  text-align: center;
}

.fulltoplist {
  text-decoration: none;
  display: flex;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;

}

@media (max-width: 475px) {
   .fulltoplist {
    padding: 15px;

  }
}

.mus {
  font-size: 30px;
  color: white;
}

@media (max-width: 800px) {
   .mus {
    display: none;

  }
}


@media (max-width: 475px) {
   .mus {
    display: none;

  }
}

.mus:hover {
  font-size: 35px;
  color: white;
}



.home, .address, .microphone, .pencil {
  color: transparent;
}






.cent {
  display: block;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;


}

.anc {
  text-decoration: none;
  color: white;
  font-size: 25px;
  font-family: 'Bebas Neue', cursive;
}

@media (max-width: 475px) {
   .anc {
     font-size: 22px;

  }
  .home, .address, .microphone, .pencil  {
    color: white;
  }
  .home:hover, .address:hover, .microphone:hover, .pencil:hover  {
    color: white;
    font-size: 24px;
  }


}



.anc:hover {
  font-size: 30px;
  color: white;
}

.anc:hover i{
  font-size: 30px;
  color: white;
}

.mainline {
  font-size: 200px;
  margin: 10px;
  padding-top: 200px;
  color: White;
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 25px;
  text-shadow: 5px 5px 10px black;
}

@media (max-width: 475px) {
   .mainline {
    font-size: 100px;
    padding-top: 125px;
    margin-left: 30px;

  }
}

.centerplayer {
  border: 5px solid white;
  text-align: center;
  background: rgb(255,255,255,0.8);
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  width: 400px;
  padding: 40px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 0px;
  box-shadow: 2px 2px 5px;
  font-size: 20px;
}

@media (max-width: 475px) {
   .centerplayer {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-left: 50px;
    font-size: 15px;

  }
}

.listen {
  background: none;
  font-size: 50px;
  color: white;
  border: 2px solid white;
  font-family: 'Bebas Neue', cursive;
  padding: 10px;
  padding-bottom: 5px;
  cursor: pointer;
}

@media (max-width: 475px) {
   .listen {
    display: none;

  }
}

.listen:hover {
  border: 3px solid white;
  font-size: 55px;
  color: white;
}



.buttondiv {
  padding-bottom: 200px;
  font-size: 20px;
}

@media (max-width: 475px) {
   .buttondiv {
    padding-bottom: 50px;

  }
}



.second {

}
