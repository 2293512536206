
.mainabout {
  padding-top: 75px;
  padding-bottom: none;
}

.AboutHeader {
  font-size: 120px;
  margin: 50px;
  padding-top: 40px;
  color: White;
  font-family: 'Bebas Neue', cursive;
  letter-spacing: 25px;
  text-shadow: 5px 5px 10px black;
}

@media (max-width: 475px) {
   .AboutHeader {
    font-size: 60px;
    margin-right: 30px;

  }
}


.aboutparagraph {
  background: black;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -70px;
  padding-top: 40px;
  padding-bottom: 100px;
}

@media (max-width: 475px) {
   .aboutparagraph {
    padding-top: 20px;

  }
}

.behindtext {
  background: black;
}

.text {
  font-size: 30px;
  padding: 15px;
  color: white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Oswald', sans-serif;
}

@media (max-width: 475px) {
   .text {
    font-size: 20px;

  }
}
