.whole {
  padding-top: 150px;
  padding-bottom: 50px;
}


.lyricspage {
  border: 4px solid white;
  border-radius: 10px;
  padding-top: 20px;
  background: rgb(0,0,0,0.8);
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  padding-right: 20px;
  padding-top: 30px;

}

@media (max-width: 475px) {
   .lyricspage {
    width: 80%;

  }

}

.lyricsheader {
  font-size: 100px;
  color: white;
}

@media (max-width: 475px) {
   .lyricsheader {
    font-size: 100px;
  }


}

.lyricslist {
  margin-top: 1px;
  list-style: none;
}

@media (max-width: 475px) {
   .lyricslist {
    padding-top: 0px;
    margin-top: -30px;

  }
}




.lyrics {
  padding: 15px;
  text-align: left;


}

@media (max-width: 475px) {
   .lyrics {
    text-align: center;
    margin-left: -65px;
    margin-top: 10px;

  }
}

.songjson {
  font-size: 50px;
  color: white;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
}

@media (max-width: 1100px) {
   .songjson {
    font-size: 40px;
  }
}

@media (max-width: 920px) {
   .songjson {
    font-size: 30px;
  }
}

@media (max-width: 730px) {
   .songjson {
    font-size: 25px;
  }
}

@media (max-width: 475px) {
   .songjson {
    font-size: 25px;
  }
}



.songjson:hover {
  color: white;
}

.songjson:hover .caret {
  color: white;
}

.caret {
  color: transparent;
}
